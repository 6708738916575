import React, { Component } from 'react';
import Slider from "react-slick";

import './MenuSection.scss';
import Title from '../UI/Title/Title';
import Description from '../UI/Description/Description';
import Dropdown from '../UI/Dropdown/Dropdown';
import { SamplePrevArrow, SampleNextArrow } from '../../shared/slider';
import placeholderImg from '../../assets/images/restaurant-page/placeholder.png';

class MenuSection extends Component {
  renderMenuGallery = (menu) => {
    return (
      menu.map((slide, idx) => {
        return (
          <div key={idx} className="slide">
            <p className="img" style={{
              backgroundImage: `url(${slide})`
            }}></p>
          </div>
        );
      })
    );
  };

  renderMock = () => {
    return (
      <div className="slide">
        <p className="img" style={{
          backgroundImage: `url(${placeholderImg})`
        }}></p>
      </div>
    );
  };

  render() {
    const { description, menu, menu_list, menu_gallery } = this.props.restaurant;

    const { currentLang, t } = this.props

    const slideSettings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 3000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      fade: true,
      cssEase: 'linear'
    };

    const menuList = [
      {
        id: '_' + Math.random().toString(36).substr(2, 9),
        name: t('restaurantPage.menuSection.mainMenu'),
        url: menu
      },
      ...menu_list
    ];

    return (
      <section className="MenuSection">
        <div className="Container">
          <div className="Left">
            <Title icon="cake">{t('restaurantPage.menuSection.title')} {description.title}</Title>
            <Description className="Description">{description.menu_description}</Description>
            {
              menuList.length > 1 ? (
                <Dropdown
                  dropdownBtn={t('restaurantPage.menuSection.goToMenuDropdown')}
                  content={menuList}
                  className={currentLang === 'en' ? 'en' : ''}
                />
              ) : (
                <p className="Menu">
                  <a
                    href={menu}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={currentLang === 'en' ? 'en' : ''}
                  >
                    {t('restaurantPage.menuSection.goToMenuBtn')}
                  </a>
                </p>
              )
            }
          </div>
          <div className="Right">
            <div className="Wrapper">
              <Slider {...slideSettings}>
                {menu_gallery.length ? this.renderMenuGallery(menu_gallery) : this.renderMock()}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default MenuSection;
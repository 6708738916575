import React from 'react';

import './Restaurants.scss';
import Restaurant from './Restaurant/Restaurant';

const restaurants = (props) => {
  const { t } = props;

  const attachedClasses = ["List"];

  if (window.safari) {
    attachedClasses.push("Safari");
  }

  return (
    <ul className={attachedClasses.join(' ')}>
      {
        props.restaurants.map(restaurant => {
          return (
            <Restaurant
              key={restaurant.id}
              restaurant={restaurant}
              onRedirectToRestaurant={(id) => props.onRedirectToRestaurant(id)}
              t={t}
            />
          );
        })
      }
    </ul>
  );
}

export default restaurants;
import React from 'react';

import './DownloadSection.scss';
import Title from '../UI/Title/Title';
import Description from '../UI/Description/Description';
import Links from './Links/Links';

const downloadSection = (props) => {
  const { t } = props;

  return (
    <section className="DownloadSection">
      <Title>{t('appPage.download.title')}</Title>
      <Description>{t('appPage.download.description')}</Description>
      <Links />
    </section>
  );
};

export default downloadSection;
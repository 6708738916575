import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';

import './MainPage.scss';
import Aux from '../../hoc/Aux/Aux';
import MainSection from '../../components/MainSection/MainSection';
import Card from '../../components/Card/Card';
import RestaurantsSection from '../../components/RestaurantsSection/RestaurantsSection';
import PhotoGallerySection from '../../components/PhotoGallerySection/PhotoGallerySection';
import BonusSystemSection from '../../components/BonusSystemSection/BonusSystemSection';

import aboutRestaurantMainImg from '../../assets/images/main-page/about-restaurant.png';
import basilicImg from '../../assets/images/main-page/basilic.png';
import * as actions from '../../store/actions/index';
import { ggroupTitle } from '../constants';

class MainPage extends Component {
  componentDidMount() {
    const { guestToken, pageSize, currentLang, additionalToken } = this.props;

    this.props.onFetchRestaurants(guestToken, currentLang);
    this.props.onFetchEvents(pageSize, additionalToken, currentLang);
  }

  componentDidUpdate(prevProps) {
    const { guestToken, pageSize, currentLang, additionalToken } = this.props;

    if (prevProps.guestToken !== guestToken || prevProps.currentLang !== currentLang) {
      this.props.onFetchRestaurants(guestToken, currentLang);
      // this.props.onFetchEvents(pageSize, guestToken, currentLang);
    }

    if (prevProps.additionalToken !== additionalToken) {
      this.props.onFetchEvents(pageSize, additionalToken, currentLang);
    }

    if (window.innerWidth < 1025) {
      window.onpopstate = (e) => {
        document.querySelector('body').style.overflow = '';
      }
    }
  }

  redirectToRestaurant = (id) => {
    this.props.history.push({ pathname: '/' + id });
    window.scrollTo(0, 0);
  }

  render() {
    const { events, restaurants, currentLang, t } = this.props;
    let lastThreeEvent;

    if (events.length) {
      lastThreeEvent = events.slice(0, 3);
    }

    const gGroupDescription = [
      t('mainPage.about.description1', { quantity: restaurants.length }),
      t('mainPage.about.description2')
    ];

    return (
      <Aux>
        <Helmet>
          <title>{ggroupTitle} - {t('mainPage.tabTitle')}</title>
        </Helmet>
        <MainSection t={t} />
        {!!restaurants.length && (
          <Card
            className="AboutRestaurant"
            mainImg={aboutRestaurantMainImg}
            icon="cake"
            title={t('mainPage.about.title')}
            gGroupDescription={gGroupDescription}
            additionalImg={basilicImg}
          />
        )}
        <RestaurantsSection
          restaurants={restaurants}
          onRedirectToRestaurant={(id) => this.redirectToRestaurant(id)}
          t={t}
        />
        {
          lastThreeEvent ?
            <Card
              className="News"
              lastThreeEvent={lastThreeEvent}
              btn={t('mainPage.events.button')}
              navTo="/events"
            /> : null
        }
        <PhotoGallerySection
          style={{
            paddingTop: '460px',
            paddingBottom: '75px',
            background: '#121212'
          }}
          slides={[]}
          t={t}
          currentLang={currentLang}
        />
        <BonusSystemSection t={t} />
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    guestToken: state.authReducer.guestToken,
    additionalToken: state.authReducer.additionalToken, // should remove it
    events: state.eventsReducer.events,
    pageSize: state.eventsReducer.pageSize,
    restaurants: state.restaurantsReducer.restaurants,
    selectedRestaurant: state.restaurantsReducer.selectedRestaurant,
    currentLang: state.localizationReducer.currentLang
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchEvents: (pageSize, token, locale) => dispatch(actions.fetchEvents(pageSize, token, locale)),
    onFetchRestaurants: (token, locale) => dispatch(actions.fetchRestaurants(token, locale)),
  };
};

const MainPageTranslation = withTranslation()(MainPage);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainPageTranslation));

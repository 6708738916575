import React from 'react';
import { withRouter } from 'react-router-dom';

import './BonusSystemSection.scss';
import Title from '../UI/Title/Title';
import Description from '../UI/Description/Description';
import bonusSystemImg from '../../assets/images/main-page/bonus-system-phone.png';
import nextBtnImg from '../../assets/images/next-btn.svg';

const bonusSystemSection = (props) => {
  const { t } = props;

  function redirect() {
    props.history.push({ pathname: '/bonusnaya-sistema-lyubimyj-gost' });
    window.scrollTo(0, 0);
  };

  return (
    <section className="BonusSystemSection">
      <div className="Container">
        <div className="Left">
          <Title icon="branch">{t('mainPage.bonusSystem.title')}</Title>
          <Description className="Description">{t('mainPage.bonusSystem.description')}</Description>
          <div className="Detail" onClick={redirect}>
            {t('mainPage.bonusSystem.link')}
            <img src={nextBtnImg} alt="link to bonus system" />
          </div>
        </div>
        <div className="Right">
          <img src={bonusSystemImg} alt={''} />
        </div>
      </div>
    </section>
  );
}

export default withRouter(bonusSystemSection);
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';

import * as actions from '../../store/actions/index';
import Aux from '../../hoc/Aux/Aux';
import EventsSection from '../../components/EventsSection/EventsSection';
import Loader from '../../components/Loader/Loader';
import { ggroupTitle } from '../constants';

class EventsPage extends Component {
  componentDidMount() {
    const { guestToken, pageSize, currentLang, additionalToken } = this.props;

    this.props.onFetchEvents(pageSize, additionalToken, currentLang);
  }

  componentDidUpdate(prevProps) {
    const { guestToken, pageSize, currentLang, additionalToken } = this.props;

    if (prevProps.additionalToken !== additionalToken || prevProps.currentLang !== currentLang) {
      this.props.onFetchEvents(pageSize, additionalToken, currentLang);
    }
  }

  openModal = (id) => {
    const pathname = this.props.location.pathname;
    this.props.history.push({ pathname: `${pathname}/${id}` });
  }

  showMore = () => {
    const { events, guestToken, currentLang, additionalToken } = this.props;

    this.props.onShowMore(events.length, additionalToken, currentLang);
  }

  render() {
    const { events, totalEvents, isLoadingEvents, t } = this.props;
    return (
      <Aux>
        <Helmet>
          <title>{ggroupTitle} - {t('eventsPage.tabTitle')}</title>
        </Helmet>
        {
          events.length ?
            <div className="EventsWrapper">
              <EventsSection
                events={events}
                totalEvents={totalEvents}
                clicked={this.openModal}
                showMore={this.showMore}
                isLoadingEvents={isLoadingEvents}
                t={t}
              />
            </div> : <Loader />
        }
      </Aux>
    );
  }
}


const mapStateToProps = state => {
  return {
    guestToken: state.authReducer.guestToken,
    additionalToken: state.authReducer.additionalToken, // should remove it
    events: state.eventsReducer.events,
    pageSize: state.eventsReducer.pageSize,
    totalEvents: state.eventsReducer.totalEvents,
    isLoadingEvents: state.eventsReducer.isLoadingEvents,
    currentLang: state.localizationReducer.currentLang
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchEvents: (pageSize, token, locale) => dispatch(actions.fetchEvents(pageSize, token, locale)),
    onShowMore: (currentEventsLength, token, locale) => dispatch(actions.showMore(currentEventsLength, token, locale)),
  };
}

const EventsPageTranslation = withTranslation()(EventsPage);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventsPageTranslation));

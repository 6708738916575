import React, {Component} from 'react';
import moment from 'moment';

import './Event.scss';
import Title from '../../../UI/Title/Title';
import Description from '../../../UI/Description/Description';

class Event extends Component {
  selectEvent = (id) => {
    this.props.clicked(id);
  }

  render() {
    const {id, images_list, description, start, end} = this.props.event;
    const startDate = moment(start).format('DD.MM.YY HH:mm');
    const endDate = moment(end).format('DD.MM.YY HH:mm');
    return(
      <li className={'Event'} onClick={() => this.selectEvent(id)}>
        <div className="Wrapper">
          <p className="Poster"><img src={images_list.original} alt={description.title} /></p>
          <div className="InnerWrapper">
            <Title>{description.title}</Title>
            <Description className="Description">{description.description}</Description>
            <p className="Time">{`${startDate} - ${endDate}`}</p>
          </div>
        </div>
      </li>
    )
  }
};

export default Event;
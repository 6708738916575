import React, { Component } from 'react';
import Slider from "react-slick";

import './PhotoGallerySection.scss';
import Title from '../UI/Title/Title';
import photo1 from '../../assets/images/main-page/1.jpg';
import photo2 from '../../assets/images/main-page/2.jpg';
import photo3 from '../../assets/images/main-page/3.jpg';
import { SamplePrevArrow, SampleNextArrow } from '../../shared/slider';
import Modal from '../UI/Modal/Modal';
import close from '../../assets/images/close.svg';
import closeMobile from '../../assets/images/close-mobile.svg';
import Aux from '../../hoc/Aux/Aux';

class PhotoGallerySection extends Component {
  state = {
    isSwipe: false,
    isShowModal: false,
    width: 0,
    defaultSlide: 0,
    nav1: null,
    nav2: null
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  openModal = (slides, currentSlide) => {
    if (this.state.isSwipe || !this.slider.innerSlider.clickable) {
      this.setState({ isSwipe: false });
    } else {
      const foundIndex = slides.findIndex(slide => slide === currentSlide);
      document.querySelector('body').style.overflow = 'hidden';
      this.setState({ isShowModal: true, defaultSlide: foundIndex, isSwipe: false });
      this.slider.slickGoTo(foundIndex);
    }
  }

  closeModal = () => {
    document.querySelector('body').style.overflow = '';
    this.setState({ isShowModal: false });
  }

  sliderTemplate = (slides) => {
    const { isShowModal } = this.state;
    const slideSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      // speed: 3000,
      // autoplaySpeed: 3000,
      // centerMode: true,
      variableWidth: true,
      adaptiveHeight: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      initialSlide: this.state.defaultSlide,
      onSwipe: () => {
        this.setState({ isSwipe: true });
      }
    };

    return (
      <Slider {...slideSettings} ref={slider => this.slider = slider}>
        {
          slides.map((slide, idx) => {
            return (
              <div key={idx} className="slide" onClick={() => !isShowModal ? this.openModal(slides, slide) : null}>
                {
                  !isShowModal ?
                    <p
                      className="img"
                      style={{
                        backgroundImage: `url(${slide})`,
                        backgroundSize: 'cover',
                      }}
                    ></p> : <img src={slide} alt="slide" />
                }
              </div>
            );
          })
        }
      </Slider>
    );
  }

  openModal2 = (slides, currentSlide) => {
    if (this.state.isSwipe || !this.slider2.innerSlider.clickable) {
      this.setState({ isSwipe: false });
    } else {
      const foundIndex = slides.findIndex(slide => slide === currentSlide);
      document.querySelector('body').style.overflow = 'hidden';
      this.setState({ isShowModal: true, defaultSlide: foundIndex, isSwipe: false });
      this.slider2.slickGoTo(foundIndex);
    }
  }

  render() {
    const { currentLang, t } = this.props;

    let slides;
    let sectionName = ['PhotoGallerySection'];

    if (this.props.slides.length) {
      slides = this.props.slides;
    } else {
      slides = [photo1, photo2, photo3, photo1, photo2, photo3];
    }

    if (this.props.restaurantPage) {
      sectionName.push('RestaurantPage');
    }

    const sliderNavSettings = {
      asNavFor: this.state.nav1,
      ref: slider => (this.slider2 = slider),
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      autoplay: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      initialSlide: this.state.defaultSlide,
      swipeToSlide: true
    };
    const sliderForSettings = {
      asNavFor: this.state.nav2,
      ref: slider => (this.slider1 = slider),
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      swipe: false,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      initialSlide: this.state.defaultSlide
    };

    return (
      <section className={sectionName.join(' ')} style={this.props.style}>
        <div className="Container">
          <div className={`Left ${currentLang === 'en' ? 'en' : ''}`}>
            <Title icon="meat">{t('photoGallery.title')}</Title>
          </div>
          <div className="Right">
            <div className="SliderNav">
              <Slider {...sliderNavSettings}>
                {
                  slides.map((slide, idx) => {
                    return (
                      <div key={idx} className="slide" onClick={() => this.openModal2(slides, slide)}>
                        <p
                          className="img"
                          style={{
                            backgroundImage: `url(${slide})`,
                            backgroundSize: 'cover',
                          }}
                        ></p>
                      </div>
                    )
                  })
                }
              </Slider>
            </div>
          </div>
        </div>
        <div className="SliderFor">
          {
            this.state.isShowModal ?
              <Aux>
                <Modal
                  show={this.state.isShowModal}
                  modalClosed={this.closeModal}
                  smallModal={true}
                >
                  <div className="ModalContent">
                    <Slider {...sliderForSettings}>
                      {
                        slides.map((slide, idx) => {
                          return (
                            <div key={idx} className="slide">
                              <img src={slide} alt="slide" />
                            </div>
                          )
                        })
                      }
                    </Slider>
                  </div>
                  {
                    this.state.width < 768 ?
                      <div className="Close">
                        <img src={closeMobile} alt="close" onClick={this.closeModal} />
                      </div> : null
                  }
                </Modal>
                {
                  this.state.width >= 768 ?
                    <div className="Close">
                      <img src={close} alt="close" onClick={this.closeModal} />
                    </div> : null
                }
              </Aux> : null
          }
        </div>
      </section>
    );
  }
}

export default PhotoGallerySection;
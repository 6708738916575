import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  careerPayload: null
};

const careerSuccess = (state, action) => {
  return updateObject(state, {careerPayload: action.careerPayload});
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
    case actionTypes.CAREER_SUCCESS: return careerSuccess(state, action);
    default: return state;
	}
};

export default reducer;
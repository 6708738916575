import axios from '../../axios';
import * as actionTypes from './actionTypes';

export const career = (data) => {
  return dispatch => {
		axios.post('/site/v1/career/create', data)
			.then(res => {
				const careerPayload = res.data.payload;

				dispatch(careerSuccess(careerPayload));
			})
			.catch(err => {
				// dispatch(fetchCareerFail(err));
			});
	};
};

export const careerSuccess = (careerPayload) => {
  return {
    type: actionTypes.CAREER_SUCCESS,
		careerPayload
  }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';

import './RestaurantsPage.scss';
import RestaurantsSection from '../../components/RestaurantsSection/RestaurantsSection';
import Loader from '../../components/Loader/Loader';
import * as actions from '../../store/actions/index';
import { ggroupTitle } from '../constants';

class RestaurantsPage extends Component {
  componentDidMount() {
    const { guestToken, currentLang } = this.props;

    this.props.onFetchRestaurants(guestToken, currentLang);
  }

  componentDidUpdate(prevProps) {
    const { guestToken, currentLang } = this.props;

    if (prevProps.guestToken !== guestToken || prevProps.currentLang !== currentLang) {
      this.props.onFetchRestaurants(guestToken, currentLang);
    }
  }

  redirectToRestaurant = (id) => {
    this.props.history.push({ pathname: '/' + id });
    window.scrollTo(0, 0);
  }

  render() {
    const { loader, restaurants, t } = this.props;

    return (
      <div className="RestaurantsPage">
        <Helmet>
          <title>{ggroupTitle} - {t('restaurantsPage.tabTitle')}</title>
        </Helmet>
        {
          loader ?
            <Loader /> :
            <RestaurantsSection
              restaurants={restaurants}
              onRedirectToRestaurant={(id) => this.redirectToRestaurant(id)}
              t={t}
            />
        }
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    guestToken: state.authReducer.guestToken,
    loader: state.loaderReducer.loader,
    restaurants: state.restaurantsReducer.restaurants,
    currentLang: state.localizationReducer.currentLang
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchRestaurants: (token, locale) => dispatch(actions.fetchRestaurants(token, locale)),
  };
};

const RestaurantsPageTranslation = withTranslation()(RestaurantsPage);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RestaurantsPageTranslation));
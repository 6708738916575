import React, { Component } from 'react';

import './Dropdown.scss';

class Dropdown extends Component {
  state = {
    popupVisible: false
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleClick = () => {
    if (!this.state.popupVisible) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      popupVisible: !prevState.popupVisible,
    }));
  }

  handleOutsideClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  }

  render() {
    const { className, dropdownBtn, content } = this.props;

    const attachedClasses = ['Dropdown'];

    if (className) {
      attachedClasses.push(className);
    }

    return (
      <div className={attachedClasses.join(' ')} ref={node => { this.node = node; }}>
        <button
          className="DropdownBtn"
          onClick={this.handleClick}
        >
          {dropdownBtn}
        </button>
        {this.state.popupVisible && (
          <ul className="DropdownContent">
            {content.map(menu => {
              return (
                <li key={menu.id} className="DropdownItem">
                  <a href={menu.url} rel="noopener noreferrer" target="_blank">{menu.name}</a>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
};

export default Dropdown;
import React, { Component } from 'react';
import { withGoogleMap, GoogleMap } from 'react-google-maps';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';

import './MapSection.scss';
import locationImg from '../../assets/images/restaurant-page/location.svg';
import timeImg from '../../assets/images/restaurant-page/time.svg';
import phoneImg from '../../assets/images/restaurant-page/phone.svg';
import { updateWorkTime } from '../../shared/workTime';
import Aux from '../../hoc/Aux/Aux';
import mapMarker from '../../assets/images/restaurant-page/marker.svg';
import mapStyles from './GoogleMapStyles.json';

class MapSection extends Component {
  state = {
    width: 0
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const { restaurant, t } = this.props;
    const { width } = this.state;
    const updatedWorkTime = updateWorkTime(restaurant.work_time, t);
    const Map = withGoogleMap(() => (
      <GoogleMap
        defaultZoom={width > 767 ? 16 : 15}
        defaultCenter={{
          lat: +restaurant.latitude,
          lng: this.state.width > 1024 ? +restaurant.longitude - 0.008 : +restaurant.longitude
        }}
        defaultOptions={{
          disableDefaultUI: true,
          draggable: true,
          keyboardShortcuts: false,
          scaleControl: true,
          // scrollwheel: true,
          styles: mapStyles
        }}
      >
        <MarkerWithLabel
          labelClass="Marker"
          opacity={0}
          position={{ lat: +restaurant.latitude, lng: +restaurant.longitude }}
          labelAnchor={new window.google.maps.Point(+restaurant.latitude, +restaurant.longitude)}
        >
          <div
            className="Wrapper"
            style={{
              width: '53px',
              height: '76px',
              backgroundImage: `url('${mapMarker}')`
            }}
          >
            <p
              className="Circle"
              style={{
                position: 'relative',
                top: '8px',
                left: '7px',
                width: '39px',
                height: '39px',
                borderRadius: '50%',
                backgroundColor: 'black',
                overflow: 'hidden'
              }}
            >
              <img
                src={restaurant.images_list.logo}
                style={{
                  position: 'relative',
                  width: '41px',
                  height: '25px',
                  top: '7px'
                }}
                alt="circle"
              />
            </p>
          </div>
        </MarkerWithLabel>
      </GoogleMap>
    ));

    return (
      <section className="MapSection">
        <div className="Map">
          <Map
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
        {
          width > 1024 ?
            <ul className="MapList">
              <li>
                <img src={locationImg} alt="Местонахождение" />
                <a
                  href={`https://www.google.com/maps/@${restaurant.latitude},${restaurant.longitude},17z/${restaurant.google_cid}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >{restaurant.description.address}</a>
              </li>
              <li>
                <img src={timeImg} alt="Телефон" />
                <ul className="WorkTimeList">
                  {updatedWorkTime.map(time => <li key={time}>{time}</li>)}
                </ul>
              </li>
              <li>
                {
                  restaurant.telephones.map(tel => {
                    return (
                      <Aux key={tel}>
                        <img src={phoneImg} alt="Телефон" />
                        <a href={`tel: ${tel}`}>{tel}</a>
                      </Aux>
                    );
                  })
                }
              </li>
            </ul> : null
        }
      </section>
    );
  }
};

export default MapSection;
import nativeAxios from 'axios';

import * as actionTypes from './actionTypes';
import axios from '../../axios';

const fetchGuestUserSuccess = (token) => {
  return {
    type: actionTypes.SET_GUEST_USER_SUCCESS,
    token
  };
};

const fetchGuestUserFail = (err) => {
  return {
    type: actionTypes.SET_GUEST_USER_FAIL,
    err
  };
};

export const fetchGuestUser = () => {
  return dispatch => {
    axios.post('/site/v1/user/guest')
      .then(res => {
        const { token } = res.data.payload;
        dispatch(fetchGuestUserSuccess(token));
      })
      .catch(err => {
        dispatch(fetchGuestUserFail(err))
      })
  };
};

// delete it when app completely move to g-group-app
const fetchAdditionalUserSuccess = (token) => {
  return {
    type: actionTypes.SET_ADDITIONAL_USER_SUCCESS,
    token
  };
};

const fetchAdditionalUserFail = (err) => {
  return {
    type: actionTypes.SET_ADDITIONAL_USER_FAIL,
    err
  };
};

export const fetchAdditionalUser = () => {
  return dispatch => {
    nativeAxios.post('https://g-group-app.eatery.club/site/v1/user/guest')
      .then(res => {
        const { token } = res.data.payload;
        dispatch(fetchAdditionalUserSuccess(token));
      })
      .catch(err => {
        dispatch(fetchAdditionalUserFail(err))
      })
  };
};
export const SET_GUEST_USER_SUCCESS = 'SET_GUEST_USER_SUCCESS';
export const SET_GUEST_USER_FAIL = 'SET_GUEST_USER_FAIL';

// should remove it 
export const SET_ADDITIONAL_USER_SUCCESS = 'SET_ADDITIONAL_USER_SUCCESS';
export const SET_ADDITIONAL_USER_FAIL = 'SET_ADDITIONAL_USER_FAIL';

// export const FETCH_RESTAURANTS_START = 'FETCH_RESTAURANTS_START'
export const FETCH_RESTAURANTS = 'FETCH_RESTAURANTS';
export const FETCH_RESTAURANTS_SUCCESS = 'FETCH_RESTAURANTS_SUCCESS';
export const FETCH_RESTAURANTS_FAIL = 'FETCH_RESTAURANTS_FAIL';


// export const SELECT_RESTAURANT = 'SELECT_RESTAURANT';
// export const FETCH_RESTAURANT_BY_ID_START = 'FETCH_RESTAURANT_BY_ID_START';
export const FETCH_RESTAURANT_BY_ID = 'FETCH_RESTAURANT_BY_ID';
export const FETCH_RESTAURANT_BY_ID_SUCCESS = 'FETCH_RESTAURANT_BY_ID_SUCCESS';
export const FETCH_RESTAURANT_BY_ID_FAIL = 'FETCH_RESTAURANT_BY_ID_FAIL';
export const CLEAR_RESTAURANT_DATA = 'CLEAR_RESTAURANT_DATA';


export const FETCH_EVENTS_START = 'FETCH_EVENTS_START';
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAIL = 'FETCH_EVENTS_FAIL';
export const SHOW_MORE = 'SHOW_MORE';
export const SELECT_EVENT = 'SELECT_EVENT';
export const FETCH_SELECT_EVENT_SUCCESS = 'FETCH_SELECT_EVENT_SUCCESS';

export const CAREER_SUCCESS = 'CAREER_SUCCESS';

export const SET_LOADER = 'SET_LOADER';

export const CHANGE_LANG = 'CHANGE_LANG';
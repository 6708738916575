import React, { Component } from 'react';

import './LanguageDropdown.scss';

class LanguageDropdown extends Component {
  state = {
    isShowPopup: false
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleClick = () => {
    if (!this.state.isShowPopup) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      isShowPopup: !prevState.isShowPopup,
    }));
  }

  handleOutsideClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  }

  selectLanguage = (lang) => {
    this.props.onSelectLanguage(lang);
    this.handleClick();
  };

  render() {
    const { languages, currentLang } = this.props;
    const { isShowPopup } = this.state;

    return (
      <div className="LanguageDropdown" ref={node => { this.node = node; }}>
        <p
          className="CurrentLang"
          onClick={this.handleClick}
        >
          {
            languages.find(lang => lang.value === currentLang).text
          }
        </p>

        {
          isShowPopup && (
            <ul className="Languages">
              {
                languages.map(lang => {
                  const attachedClasses = ['Language'];

                  if (lang.isSelected) {
                    attachedClasses.push('Selected');
                  }

                  return (
                    <li
                      key={lang.id}
                      className={attachedClasses.join(' ')}
                      onClick={() => this.selectLanguage(lang)}
                    >
                      {lang.text}
                    </li>
                  )
                })
              }
            </ul>
          )
        }
      </div>
    );
  }
};

export default LanguageDropdown;
import axios from 'axios';

import * as actionTypes from './actionTypes';
// import axios from '../../axios'; <- should back this

export const fetchEvents = (pageSize, token, locale) => {
  return dispatch => {
    // Should back custom axios with base url && should make request to 'site', not to 'api'
    axios.get(`https://g-group-app.eatery.club/site/v1/events/feed?pageSize=${pageSize}`, {
      headers: { 
        Authorization: `Bearer ${token}`,
        locale
      }
    })
			.then(res => {
        const fetchedEvents = res.data.payload;
        const totalEvents = res.data.meta.total;

        dispatch(fetchEventsSuccess(fetchedEvents, totalEvents));
			})
			.catch(err => {
        dispatch(fetchEventsFail(err));
			});
  };
};

export const fetchEventsStart = () => {
	return {
		type: actionTypes.FETCH_EVENTS_START
	};
};

export const fetchEventsSuccess = (events, totalEvents) => {
  return {
    type: actionTypes.FETCH_EVENTS_SUCCESS,
    events,
    totalEvents
  }
}

export const fetchEventsFail = (err) => {
  return {
    type: actionTypes.FETCH_RESTAURANTS_FAIL,
    err
  }
}

export const showMore = (currentEventsLength, token, locale) => {
  return dispatch => {
    dispatch(fetchEventsStart());
    dispatch(fetchEvents(currentEventsLength + 6, token, locale));
  };
}

export const selectEvent = (id, token, locale) => {
  return dispatch => {
    // should change it
    axios.get('https://g-group-app.eatery.club/site/v1/events/feed', {
      headers: { 
        Authorization: `Bearer ${token}`,
        locale
      }
    })
      .then(res => {
        const fetchedEvents = res.data.payload;

        dispatch(fetchSelectEventSuccess(fetchedEvents, id));
      })
  }
};

export const fetchSelectEventSuccess = (events, id) => {
  return {
    type: actionTypes.FETCH_SELECT_EVENT_SUCCESS,
    events,
    id
  };
};
import React from 'react';

import './Restaurant.scss';
import { updateWorkTime } from '../../../../shared/workTime';

const restaurant = (props) => {
  const { restaurant, t } = props;
  const updatedWorkTime = updateWorkTime(restaurant.work_time, t);

  return (
    <li className="Item" onClick={() => props.onRedirectToRestaurant(restaurant.id)}>
      <div className="Poster">
        <img className="Square" src={restaurant.images_list.square} alt={restaurant.description.title} />
        <img className="Logo" src={restaurant.images_list.logo} alt={restaurant.description.title} />
      </div>
      <div className="Description">
        <p className="Type">{restaurant.description.subtitle}</p>
        <p className="Address">{restaurant.description.address}</p>
        <ul className="WorkTimeList">
          {updatedWorkTime.map(time => <li key={time} className="WorkTime">{time}</li>)}
        </ul>
      </div>
    </li>
  );
}

export default restaurant;
import React from 'react';

import './SocialBtns.scss';
import SocialBtn from './SocialBtn/SocialBtn';

const socialBtns = (props) => {
  return(
    <ul className="SocialBtns">
      {
        props.socialBtns.map(social => {
          return (
            <SocialBtn 
              key={social.name}
              navTo={social.navTo} 
              img={social.name}
            >
              {social.name}
            </SocialBtn>
          );
        })
      }
    </ul>
  );
}

export default socialBtns;
import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';

import './Card.scss';
import '../../containers/EventsPage/EventsPage.scss';
import Title from '../UI/Title/Title';
import Button from '../UI/Button/Button';
import Aux from '../../hoc/Aux/Aux';
import EventModal from '../UI/Modal/EventModal/EventModal';

class Card extends Component {
  state = {
    isShowModal: false,
    selectedEvent: null,
    width: 0
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  redirectToNews = () => {
    this.props.history.push({ pathname: this.props.navTo });
    window.scrollTo(0, 0);
  };

  toggleModal = (event) => {
    if (event) {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = '';
    }
    
    this.setState({isShowModal: !this.state.isShowModal, selectedEvent: event});
  }

  render() {
    let cardTemplate;

    if (this.props.className === 'AboutRestaurant') {
      cardTemplate = (
        <Aux>
          <p className="MainImg"><img src={this.props.mainImg} alt={this.props.title} /></p>
          <div className="InnerWrapper">
            <Title icon={this.props.icon}>{this.props.title}</Title>
            {this.props.gGroupDescription.map(desc => <p key={desc} className="Description">{desc}</p>)}
          </div>
        </Aux>
      );
    } else if (this.props.className === 'News') {
      cardTemplate = (
        <Aux>
          <ul className="NewsList">
            {this.props.lastThreeEvent.map(event => <li key={event.description.title} className="NewsItem" onClick={() => this.toggleModal(event)}><img src={event.images_list.original} alt={event.description.title} /></li>)}
          </ul>
          <Button clicked={() => this.redirectToNews()}>{this.props.btn}</Button>
        </Aux>
      );
    }
  
    return (
      <Aux>
        <div className={this.props.className}>
          <div className="Wrapper">
            {cardTemplate}
          </div>
        </div>
        {
          this.state.isShowModal ?
            <div className="EventsWrapper">
              <EventModal 
                show={this.state.isShowModal}
                selectedEvent={this.state.selectedEvent}
                width={this.state.width}
                closeModal={() => this.toggleModal(null)}

              />
            </div> : null
        }
      </Aux>
    );
  }
};

export default withRouter(Card);
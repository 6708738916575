export const updateWorkTime = (time, t) => {
  let uniqueWorkTime = [...new Set(time)]; 
  let updatedWorkTime = [];

  if (uniqueWorkTime.length === 1) {
    updatedWorkTime = uniqueWorkTime.map(uTime => {
      return `${t('restaurantsPage.monday')} - ${t('restaurantsPage.sunday')} ${t('restaurantsPage.from')} ${uTime.slice(0, 5)} ${t('restaurantsPage.to')} ${uTime.slice(6)}`;
    });
  } else if (uniqueWorkTime.length === 2) {
    const wTime = [];
    uniqueWorkTime.forEach(uTime => {
      const from = uTime.slice(0, 5);
      const to = uTime.slice(6);
      wTime.push(`${t('restaurantsPage.from')} ${from} ${t('restaurantsPage.to')} ${to}`);
    });

    updatedWorkTime.push(`${t('restaurantsPage.monday')} - ${t('restaurantsPage.thursday')} ${wTime[0]}`, `${t('restaurantsPage.friday')} - ${t('restaurantsPage.saturday')} ${wTime[1]}`, `${t('restaurantsPage.sunday')} ${wTime[0]}`);
  } else if (uniqueWorkTime.length === 3) {
    const wTime = []
    uniqueWorkTime.forEach(uTime => {
      const from = uTime.slice(0, 5);
      const to = uTime.slice(6);
      wTime.push(`${t('restaurantsPage.from')} ${from} ${t('restaurantsPage.to')} ${to}`);
    });

    updatedWorkTime.push(`${t('restaurantsPage.monday')} - ${t('restaurantsPage.thursday')} ${wTime[0]}`, `${t('restaurantsPage.friday')} - ${t('restaurantsPage.saturday')} ${wTime[1]}`, `${t('restaurantsPage.sunday')} ${wTime[2]}`);
  }

  return updatedWorkTime;
};
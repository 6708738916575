import React from 'react';

import './SocialBtn.scss';

const socialBtn = (props) => (
  <li>
    <a
      href={props.navTo} 
      className={['SocialBtn', [props.children]].join(' ')}
      target="_blank"
      rel="noopener noreferrer"
    >{props.children}</a>
  </li>
);

export default socialBtn;
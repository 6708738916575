import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  restaurants: [],
  restaurant: null,
  restaurantErr: null
};

const fetchRestaurants = (state, action) => {
  return updateObject(state);
};

const fetchRestaurantsSuccess = (state, action) => {
  return updateObject(state, {restaurants: action.restaurants});
}

const fetchRestaurantById = (state, action) => {
  return updateObject(state);
}

const fetchRestaurantByIdSuccess = (state, action) => {
  return updateObject(state, {restaurant: action.restaurant});
}

const fetchRestaurantByIdFail = (state, action) => {
  return updateObject(state, {
    restaurantErr: action.err
  });
};

const clearRestaurantData = (state, action) => {
  return updateObject(state, {
    restaurant: null
  });
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
    case actionTypes.FETCH_RESTAURANTS: return fetchRestaurants(state, action);
    case actionTypes.FETCH_RESTAURANTS_SUCCESS: return fetchRestaurantsSuccess(state, action);
    case actionTypes.FETCH_RESTAURANT_BY_ID_FAIL: return fetchRestaurantByIdFail(state, action);
    case actionTypes.FETCH_RESTAURANT_BY_ID: return fetchRestaurantById(state, action);
    case actionTypes.FETCH_RESTAURANT_BY_ID_SUCCESS: return fetchRestaurantByIdSuccess(state, action);
    case actionTypes.CLEAR_RESTAURANT_DATA: return clearRestaurantData(state, action);
    default: return state;
  }
}

export default reducer;
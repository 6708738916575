import React from 'react';

import './Title.scss';
import glassIcon from '../../../assets/images/glass.svg';
import meatIcon from '../../../assets/images/meat.svg';
import branchIcon from '../../../assets/images/branch.svg';
import cakeIcon from '../../../assets/images/cake.svg';
import cakeWhiteIcon from '../../../assets/images/cake-white.svg';
import orangeIcon from '../../../assets/images/orange.svg';
import orangeWhiteIcon from '../../../assets/images/orange-white.svg';

const switchIcon = (iconName) => {
  switch(iconName) {
    case 'glass':
      return glassIcon;
    case 'meat':
      return meatIcon;
    case 'branch':
      return branchIcon;
    case 'cake':
      return cakeIcon;
    case 'cake-white':
      return cakeWhiteIcon;
    case 'orange':
      return orangeIcon;
    case 'orange-white':
      return orangeWhiteIcon;
    default:
      return;
  }
}

const title = (props) => (
  <h2 className="TitleWrapper">
    {props.icon ? <img src={switchIcon(props.icon)} alt="icon" /> : null}
    <span className="Title">{props.children}</span>
  </h2>
);

export default title;
import React from 'react';

import prevBtn from '../assets/images/prev-btn.svg';
import prevBtnDisabled from '../assets/images/prev-btn-disabled.svg';
import nextBtn from '../assets/images/next-btn.svg';
import nextBtnDisabled from '../assets/images/next-btn-disabled.svg';

export const SamplePrevArrow = (props, t) => {
  const langEl = document.querySelector('header .CurrentLang');

  const ruOption = 'Ru';
  const uaOption = 'Ua';
  const engOption = 'En';

  let prevBtnText;
  
  if (langEl) {
    const text = langEl.innerText;

    if (text === ruOption || text === uaOption) {
      prevBtnText = 'Назад';
    } else if (text === engOption) {
      prevBtnText = 'Back';
    }
  }

  const { className, style, onClick } = props;

  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={className.includes('slick-disabled') ? prevBtnDisabled : prevBtn} alt={'prev'} />
      <p>{prevBtnText}</p>
    </div>
  );
};

export const SampleNextArrow = (props) => {
  const langEl = document.querySelector('header .CurrentLang');

  const ruOption = 'Ru';
  const uaOption = 'Ua';
  const engOption = 'En';

  let nextBtnText;

  if (langEl) {
    const text = langEl.innerText;

    if (text === ruOption) {
      nextBtnText = 'Дальше';
    } else if (text === uaOption) {
      nextBtnText = 'Далі';
    } else if (text === engOption) {
      nextBtnText = 'Forward';
    }
  }

  const { className, style, onClick } = props;

  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <p>{nextBtnText}</p>
      <img src={className.includes('slick-disabled') ? nextBtnDisabled : nextBtn} alt={'next'} />
    </div>
  );
}
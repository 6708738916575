import React from 'react';

import './EventsSection.scss';
import Title from '../UI/Title/Title';
import Events from './Events/Events';

const eventsSection = (props) => {
  const { events, totalEvents, isLoadingEvents, t } = props;

  return (
    <section className="EventsSection">
      <div className="Container">
        <Title icon="glass">{t('eventsPage.title')}</Title>
        <Events events={events} clicked={props.clicked} />
        {
          totalEvents > events.length ?
            <div className="ShowMoreEvents">
              <span className="ShowMore" onClick={props.showMore}>{t('eventsPage.showMore')}</span>
              {
                isLoadingEvents ?
                  <p className="Loading">
                    <span></span>
                    <span></span>
                    <span></span>
                  </p> : null
              }

            </div> : null
        }
      </div>
    </section>
  );
};

export default eventsSection;
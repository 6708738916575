import React from 'react';

import classes from './DrawerToggle.scss';
import menuImg from '../../../../../assets/images/menu.svg';

const drawerToggle = (props) => {
  return (
    <div 
      className={classes.DrawerToggle}
      onClick={props.clicked}
    >
      <img src={menuImg} alt="menu" />
    </div>
  )
};

export default drawerToggle;
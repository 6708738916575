import React from 'react';

import './Footer.scss';
import Logo from '../Logo/Logo';

const email = 'info@g-group.rest';

const footer = (props) => {
  const { t } = props;

  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="Wrapper">
        <Logo />
        <a href={`mailto:${email}`} className="Email">email: {email}</a>
      </div>
      <p className="Copyright">© {currentYear}.{t('footer.copyright')}</p>
    </footer>
  );
};

export default footer;
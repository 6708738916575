import * as actionTypes from './actionTypes';
import axios from '../../axios';
import { setLoader } from './loader';

export const fetchRestaurants = (token, locale) => {
  return dispatch => {
    dispatch(setLoader(true));

    axios.get('/site/v1/establishments', {
      headers: { 
        Authorization: `Bearer ${token}`,
        locale
      }
    })
      .then(res => {
        const fetchedRestaurants = res.data.payload;

        dispatch(fetchRestaurantsSuccess(fetchedRestaurants));
        dispatch(setLoader(false));
      })
      .catch(err => {
        dispatch(fetchRestaurantsFail(err));
        dispatch(setLoader(true));
      });
  };
};

export const fetchRestaurantsSuccess = (restaurants) => {
  return {
    type: actionTypes.FETCH_RESTAURANTS_SUCCESS,
    restaurants
  }
}

export const fetchRestaurantsFail = (err) => {
  return {
    type: actionTypes.FETCH_RESTAURANTS_FAIL,
    err
  }
}


export const fetchRestaurantById = (id, token, locale) => {
  return dispatch => {
    dispatch(setLoader(true));

    axios.get(`/site/v1/establishments/${id}`, {
      headers: { 
        Authorization: `Bearer ${token}`,
        locale
      }
    })
      .then(res => {
        const fetchedRestaurant = res.data.payload;

        dispatch(fetchRestaurantByIdSuccess(fetchedRestaurant));
        dispatch(setLoader(false));
      })
      .catch(err => {
        dispatch(fetchRestaurantByIdFail(err));
        dispatch(setLoader(true));
      });
  };
}

export const fetchRestaurantByIdSuccess = (restaurant) => {
  return {
    type: actionTypes.FETCH_RESTAURANT_BY_ID_SUCCESS,
    restaurant
  }
}

export const fetchRestaurantByIdFail = (err) => {
  return {
    type: actionTypes.FETCH_RESTAURANT_BY_ID_FAIL,
    err
  }
}

export const clearRestaurantData = () => {
  return {
    type: actionTypes.CLEAR_RESTAURANT_DATA,
  };
};
import React from 'react';

import './Links.scss';
import appStoreImg from '../../../assets/images/app-page/app-store.svg';
import googlePlayImg from '../../../assets/images/app-page/google-play.svg';

const downloadLinks = [
  {name: 'App Store', className: 'AppStore', img: appStoreImg, navTo: 'https://apps.apple.com/us/app/g-group-restaurant-company/id1467395909?ls=1'},
  {name: 'Google Play', className: 'GooglePlay', img: googlePlayImg, navTo: 'https://play.google.com/store/apps/details?id=com.ggroup.gbsfo'}
]

const links = (props) => (
  <div className="Links">
    {
      downloadLinks.map(link => {
        return (
          <a key={link.className} className={link.className} href={link.navTo} target="_blank" rel="noopener noreferrer">
            <img src={link.img} alt={link.name} />
          </a>      
        )
      })
    }
  </div>
);

export default links;
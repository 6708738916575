import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';

import * as actions from '../../store/actions';

import './FranchisePage.scss';
import Aux from '../../hoc/Aux/Aux';
import Content from '../../components/Content/Content';
import { ggroupTitle } from '../constants';

class franchisePage extends Component {
  componentDidMount() {
    const { guestToken, currentLang, onFetchRestaurants } = this.props;

    guestToken && onFetchRestaurants(guestToken, currentLang);
  }

  componentDidUpdate(prevProps) {
    const { guestToken, currentLang, onFetchRestaurants } = this.props;

    if (
      prevProps.guestToken !== guestToken
      || prevProps.currentLang !== currentLang
    ) {
      onFetchRestaurants(guestToken, currentLang);
    }
  }

  render() {
    const { t, restaurants } = this.props;

    return (
      <Aux>
        <Helmet>
          <title>{ggroupTitle} - {t('franchisePage.tabTitle')}</title>
        </Helmet>
        <Content 
          restaurants={restaurants}
          sectionName="FranchiseSection" 
          franchise={true}
          t={t}
        />
      </Aux>
    );
  };
};

const mapStateToProps = state => {
  return {
    guestToken: state.authReducer.guestToken,
    restaurants: state.restaurantsReducer.restaurants,
    currentLang: state.localizationReducer.currentLang
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchRestaurants: (token, locale) => dispatch(actions.fetchRestaurants(token, locale)),
  };
};

const franchisePageTranslation = withTranslation()(franchisePage);

export default connect(mapStateToProps, mapDispatchToProps)(franchisePageTranslation);

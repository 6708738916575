import React, {Component} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Layout from './hoc/Layout/Layout';
import MainPage from './containers/MainPage/MainPage';
import RestaurantPage from './containers/RestaurantPage/RestaurantPage';
import RestaurantsPage from './containers/RestaurantsPage/RestaurantsPage';
import EventsPage from './containers/EventsPage/EventsPage';
import EventPage from './containers/EventPage/EventPage';
import FranchisePage from './containers/FranchisePage/FranchisePage';
import CareerPage from './containers/CareerPage/CareerPage';
import AppPage from './containers/AppPage/AppPage';
import './App.scss';

class App extends Component {
  render() {
    let routes = (
      <Layout>
        <Switch>
          <Route path="/bonusnaya-sistema-lyubimyj-gost" component={AppPage} />
          <Route path="/career" component={CareerPage} />
          <Route path="/franchise" component={FranchisePage} />
          <Route path="/events/:id" component={EventPage} />
          <Route path="/events" component={EventsPage} />
          <Route path="/restaurants" component={RestaurantsPage} />
          <Route path="/:id" component={RestaurantPage} />
          <Route path="/" exact component={MainPage} />
          <Redirect to="/" />
        </Switch>
      </Layout>
    );
    
    return (
      <div className="App">
        {routes}
      </div>
    );
  }
}

export default App;

import React from 'react';
import InputMask from 'react-input-mask';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

import './Input.scss';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType, FilePondPluginFileEncode);

const input = (props) => {
  const { t } = props;

  let inputElement = null;
  let reference;
  const inputClasses = ['Input'];

  if (props.invalid && props.shouldValidate && props.touched) {
    inputClasses.push('Invalid');
  }

  switch (props.elementType) {
    case ('input'):
      inputElement = <input
        className={inputClasses.join(' ')}
        {...props.elementConfig}
        value={props.value}
        onChange={props.changed}
        onBlur={props.blur}
      />;
      break;
    case ('phone'):
      inputElement = <InputMask
        className={inputClasses.join(' ')}
        {...props.elementConfig}
        value={props.value}
        onChange={props.changed}
        mask="+38 (099) 999 99 99"
        maskChar=' '
      />;
      break;
    case ('file'):
      inputElement = <FilePond
        className={inputClasses.join(' ')}
        {...props.elementConfig}
        ref={ref => reference = ref}
        maxFiles={1}
        allowMultiple={false}
        maxFileSize="3MB"
        labelMaxFileSizeExceeded={t('careerPage.form.file.labelMaxFileSizeExceeded')}
        labelMaxFileSize={t('careerPage.form.file.labelMaxFileSize')}
        labelFileProcessing={t('careerPage.form.file.labelFileProcessing')}
        labelFileProcessingComplete={t('careerPage.form.file.labelFileProcessingComplete')}
        labelFileProcessingAborted={t('careerPage.form.file.labelFileProcessingAborted')}
        labelFileWaitingForSize={t('careerPage.form.file.labelFileWaitingForSize')}
        labelFileLoading={t('careerPage.form.file.labelFileWaitingForSize')}
        labelTapToCancel=""
        labelTapToRetry=""
        labelTapToUndo=""
        acceptedFileTypes={['application/pdf']}
        onpreparefile={() => props.changed(reference)}
        onremovefile={() => props.removed()}
      />;
      break;
    default:
      return;
  }

  return (
    <div className="Input">
      <label className="Label">{props.elementConfig.label}</label>
      {inputElement}
      <span className="Explanation">{props.elementConfig.explanation}</span>
    </div>
  );
};

export default input;
import React from 'react';
import { NavLink } from 'react-router-dom';

import './NavigationItem.scss';

const navigationItem = (props) => {
  const { link, href, exact, closed, children, target } = props;

  return (
    <li className="NavigationItem">
      {link ? (
        <NavLink 
          to={link}
          exact={exact}
          activeClassName="active"
          onClick={closed}
        >
          {children}
        </NavLink>
      ) : (
        <a href={href} target={target || '_self'}>
          {children}
        </a>
      )}
    </li>
  );
};

export default navigationItem;
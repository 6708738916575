import React from 'react';

import NavigationItems from '../NavigationItems/NavigationItems';
import './SideDrawer.scss';
import Aux from '../../../../hoc/Aux/Aux';
import closeImg from '../../../../assets/images/close-menu.svg';

const sideDrawer = (props) => {
  const { t } = props;

  let attachedClasses = ['SideDrawer', 'Close'];

  if (props.open) {
    attachedClasses = ['SideDrawer', 'Open'];
  }

  return (
    <Aux>
      <div className={attachedClasses.join(' ')}>
        <div className="Wrapper">
          <span className="CloseBurger" onClick={props.closed}>
            <img src={closeImg} alt={'close'} />
            {t('sideDrawer.close')}
          </span>
        </div>
        <nav className="Navigation">
          <NavigationItems closed={props.closed} t={t} />
        </nav>
      </div>
    </Aux>
  );
};

export default sideDrawer;
import React from 'react';
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import './CareerPage.scss';
import Aux from '../../hoc/Aux/Aux';
import Content from '../../components/Content/Content'
import { ggroupTitle } from '../constants';

const careerPage = (props) => {
  const { currentLang, t } = props;

  return (
    <Aux>
      <Helmet>
        <title>{ggroupTitle} - {t('careerPage.tabTitle')}</title>
      </Helmet>
      <Content 
        sectionName="CareerSection" 
        career={true} 
        t={t}
        currentLang={currentLang}
      />
    </Aux>
  );
};

const mapStateToProps = state => {
  return {
    currentLang: state.localizationReducer.currentLang
  };
};

const careerPageTranslation = withTranslation()(careerPage);

export default connect(mapStateToProps, null)(careerPageTranslation);

import React from 'react';
import { Element } from 'react-scroll';

import './RestaurantsSection.scss';
import Title from '../UI/Title/Title';
import Restaurants from './Restaurants/Restaurants';

const restaurantsSection = (props) => {
  const { t } = props;

  return (
    <section className="RestaurantsSection">
      <Element name="restaurants">
        <Title icon="glass">{t('restaurantsPage.title')}</Title>
      </Element>
      <Restaurants
        restaurants={props.restaurants}
        onRedirectToRestaurant={(id) => props.onRedirectToRestaurant(id)}
        t={t}
      />
    </section>
  );
};

export default restaurantsSection;
import React, { Component } from 'react';
import { Link } from 'react-scroll';
import Slider from "react-slick";
import { TimelineMax, Linear } from "gsap";

import './MainSection.scss';
import gGroupImg from '../../assets/images/main-page/g-group.svg';
import SocialBtns from '../SocialBtns/SocialBtns';

import slide1Img from '../../assets/images/main-page/slider/1.jpg';
import slide2Img from '../../assets/images/main-page/slider/2.jpg';
import slide3Img from '../../assets/images/main-page/slider/3.jpg';
import slide4Img from '../../assets/images/main-page/slider/4.jpg';
import slide5Img from '../../assets/images/main-page/slider/5.jpg';
import slide6Img from '../../assets/images/main-page/slider/6.jpg';
import slide7Img from '../../assets/images/main-page/slider/7.jpg';
import slide8Img from '../../assets/images/main-page/slider/8.jpg';
import slide9Img from '../../assets/images/main-page/slider/9.jpg';
import slide10Img from '../../assets/images/main-page/slider/10.jpg';
import slide11Img from '../../assets/images/main-page/slider/11.jpg';
import slide12Img from '../../assets/images/main-page/slider/12.jpg';
import slide13Img from '../../assets/images/main-page/slider/13.jpg';

const socialBtns = [
  { name: 'Facebook', navTo: 'https://www.facebook.com/resGGroup' }
];

class MainSection extends Component {
  state = {
    width: 0,
    isSafariBrowser: false
  };

  componentDidMount() {
    if (!window.safari) {
      setTimeout(() => {
        const duration = 3;
        // const easeEffect = Elastic.easeOut.config(.9, .7);
        const easeEffect = Linear.easeNone;
        const pathMorphStages = [
          'M591.126 101.614C473.973 60.4753 362.928 60.4753 257.991 101.614C100.586 163.322 74.3926 240.319 74.3926 311.81C74.3926 383.302 87.4147 534.914 235.66 579.729C334.49 609.606 458.524 581.321 607.762 494.875C697.199 427.37 754.104 362.344 778.475 299.795C802.847 237.246 740.397 171.186 591.126 101.614Z',
          'M642.658 190.668C547.298 110.729 444.858 70.76 335.338 70.76C171.059 70.76 74.3926 226.093 74.3926 298.797C74.3926 371.502 259.742 341.948 369.763 482.799C443.111 576.7 515.012 609.618 585.467 581.553C698.841 551.6 763.793 502.353 780.323 433.812C796.853 365.271 750.965 284.223 642.658 190.668Z',
          'M569.104 112.154C499.977 61.7543 418.17 57.3782 323.683 99.0255C181.953 161.496 74.3926 192.177 74.3926 264.303C74.3926 336.429 106.854 534.991 242.97 580.204C333.714 610.346 471.404 578.743 656.039 485.394C778.506 413.856 812.795 344.224 758.907 276.499C705.019 208.773 641.751 153.992 569.104 112.154Z',
          'M565.187 101.294C517.506 60.5821 435.416 60.5821 318.919 101.294C144.173 162.361 213.525 213.628 156.562 325.446C99.5985 437.264 -22.0979 482.776 215.608 566.657C374.079 622.578 539.084 583.092 710.621 448.202C783.606 369.309 801.853 305.491 765.36 256.747C728.868 208.004 662.143 156.186 565.187 101.294Z',
          'M674.814 97.6436C617.473 79.7212 523.061 70.76 391.578 70.76C194.353 70.76 74.3926 120.901 74.3926 199.001C74.3926 277.101 144.134 368.27 276.22 519.574C364.278 620.444 501.525 614.833 687.963 502.741C759.244 438.267 790.682 357.96 782.276 261.821C773.871 165.682 738.05 110.957 674.814 97.6436Z'
        ];
        const goToNextSlide = () => {
          if (this.slider) {
            this.slider.slickNext();
          }
        };

        this.gsap = new TimelineMax({ repeat: -1 })
          .to(`#${this.refPathId}`, duration, { ease: easeEffect, onStart: goToNextSlide, attr: { d: pathMorphStages[1] } })
          .to(`#${this.refPathId}`, duration, { ease: easeEffect, onStart: goToNextSlide, attr: { d: pathMorphStages[2] } })
          .to(`#${this.refPathId}`, duration, { ease: easeEffect, onStart: goToNextSlide, attr: { d: pathMorphStages[3] } })
          .to(`#${this.refPathId}`, duration, { ease: easeEffect, onStart: goToNextSlide, attr: { d: pathMorphStages[4] } })
          .to(`#${this.refPathId}`, duration, { ease: easeEffect, onStart: goToNextSlide, attr: { d: pathMorphStages[0] } })
      }, 4000);
    } else {
      this.setState({ isSafariBrowser: true });
    }

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const { t } = this.props
    const { isSafariBrowser } = this.state;
    const slideSettings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: isSafariBrowser ? true : false,
      fade: true,
      pauseOnHover: false,
      cssEase: 'ease-in-out'
    };
    const slides = [slide1Img, slide2Img, slide3Img, slide4Img, slide5Img, slide6Img, slide7Img, slide8Img, slide9Img, slide10Img, slide11Img, slide12Img, slide13Img];
    return (
      <section className="MainSection">
        <div className="Container">
          <div className="Wrapper">
            <div className="Left">
              <p className="MainSection-Logo">
                <img src={gGroupImg} alt="G-Group" />
              </p>
            </div>
            <div className="Right">
              <Slider {...slideSettings} ref={ref => this.slider = ref}>
                {
                  slides.map((slide, idx) => {
                    return (
                      <div key={idx} className="slide">
                        {
                          !isSafariBrowser && this.state.width > 1024 ? (
                            <svg version="1.1" preserveAspectRatio="xMidYMid meet" viewBox="0 0 858 662" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
                              <defs>
                                <path
                                  id={`morphing-path-${idx}`}
                                  ref={ref => {
                                    if (ref) {
                                      this.refPathId = ref.id;
                                    }
                                  }}
                                  d="M591.126 101.614C473.973 60.4753 362.928 60.4753 257.991 101.614C100.586 163.322 74.3926 240.319 74.3926 311.81C74.3926 383.302 87.4147 534.914 235.66 579.729C334.49 609.606 458.524 581.321 607.762 494.875C697.199 427.37 754.104 362.344 778.475 299.795C802.847 237.246 740.397 171.186 591.126 101.614Z"
                                ></path>
                              </defs>
                              <g id={`Page-${idx}`} stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id={`home-${idx}`} transform="translate(-902.000000, -164.000000)">
                                  <g transform="translate(902.000000, 164.000000)">
                                    <mask id={`mask-2-${idx}`} fill="white">
                                      <use xlinkHref={`#${this.refPathId}`}></use>
                                    </mask>
                                    <use id="Mask" xlinkHref={`#${this.refPathId}`}></use>
                                    <image mask={`url(#mask-2-${idx})`} x="-114" y="-25" width="1064" height="712" xlinkHref={slide}></image>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          ) : <p className={`slide-bg`} style={{ backgroundImage: `url(${slide})` }}></p>
                        }
                      </div>
                    );
                  })
                }
              </Slider>
            </div>
          </div>
          <div className="MainSection-Footer">
            <Link to="restaurants" spy={true} smooth={true} duration={1000}>
              <div className="Scroll">{t('mainPage.mainSection.scroll')}</div>
            </Link>
            <div className="Social">
              {t('mainPage.mainSection.socails')}
              <SocialBtns socialBtns={socialBtns} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default MainSection;
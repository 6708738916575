import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

import Title from '../UI/Title/Title';
import GroupImage from '../../assets/images/app-page/group-mobile.png';
import ChinChinImage from '../../assets/images/app-page/chin-chin-mobile.png';
import { ReactComponent as ArrowIcon } from '../../assets/images/app-page/link-arrow.svg';

import './AppContentMobile.scss';

const apps = [
  {
    id: 1,
    name: 'Chin-Chin',
    image: ChinChinImage,
    linkIOS: 'https://apps.apple.com/ua/app/%D1%81hin-%D1%81hin/id1535815120?l=ru',
    linkAndroid: 'https://play.google.com/store/apps/details?id=club.eatery.chinchin',
  },
  {
    id: 2,
    name: 'G-Group.',
    image: GroupImage,
    linkIOS: 'https://apps.apple.com/ua/app/g-group-restaurant-company/id1467395909?l=ru',
    linkAndroid: 'https://play.google.com/store/apps/details?id=com.ggroup.gbsfo',
  },
];

const AppContentMobile = props => {
  const { t } = props;

  return (
    <section className="AppContentMobileSection">
      <Title>{t('appPage.mobile.title')}</Title>
      <p className="Subtitle">
        {t('appPage.mobile.subtitle')}
      </p>
      <ul className="AppList">
        {apps.map(app => {
          const { id, name, image, linkAndroid, linkIOS } = app;
          
          return (
            <li className="AppCard" key={id}>
              <a href={isAndroid ? linkAndroid : isIOS ? linkIOS : '#'}>
                <img src={image} alt={name} />
                <p className="AppButton">
                  <span>{t('appPage.mobile.buttonText')}</span>
                  <ArrowIcon />
                </p>
              </a>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default AppContentMobile;

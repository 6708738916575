import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  guestToken: null,
  guestErr: null,
  additionalToken: null,
  additionalErr: null,
};

const setGuestUserSuccess = (state, action) => {
  return updateObject(state, {
    guestToken: action.token
  });
};

const setGuestUserFail = (state, action) => {
  return updateObject(state, {
    guestErr: action.err,
  });
};

const setAdditionalUserSuccess = (state, action) => {
  return updateObject(state, {
    additionalToken: action.token
  });
};

const setAdditionalUserFail = (state, action) => {
  return updateObject(state, {
    additionalErr: action.err,
  });
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.SET_GUEST_USER_SUCCESS: return setGuestUserSuccess(state, action);
    case actionTypes.SET_GUEST_USER_FAIL: return setGuestUserFail(state, action);
    case actionTypes.SET_ADDITIONAL_USER_SUCCESS: return setAdditionalUserSuccess(state, action);
    case actionTypes.SET_ADDITIONAL_USER_FAIL: return setAdditionalUserFail(state, action);
    default: return state;
  };
};

export default reducer;
import React, { Component } from 'react';

import './AppContent.scss';
import Aux from '../../hoc/Aux/Aux';
import Title from '../UI/Title/Title';
import DownloadLinks from '../DownloadSection/Links/Links';

import favouriteGuestImg from '../../assets/images/app-page/favourite-guest.png';
import accumulateImg from '../../assets/images/app-page/accumulate.png';
import balanceImg from '../../assets/images/app-page/balance.png';
import useImg from '../../assets/images/app-page/use.png';
import useItemsImg from '../../assets/images/app-page/use-items.png';

class AppContent extends Component {
  render() {
    const { sectionName, guest, accumulate, balance, use, t } = this.props;
    let content;

    if (guest) {
      content = (
        <Aux>
          <div className="Left">
            <Title icon="glass">{t('appPage.favouriteGuest.title')}</Title>
            <p>{t('appPage.favouriteGuest.description1')}</p>
            <p>{t('appPage.favouriteGuest.description2')}</p>
            <DownloadLinks />
          </div>
          <div className="Right">
            <img src={favouriteGuestImg} alt={''} />
          </div>
        </Aux>
      );
    } else if (accumulate) {
      content = (
        <Aux>
          <div className="Left">
            <img src={accumulateImg} alt={''} />
          </div>
          <div className="Right">
            <Title icon="meat">{t('appPage.accumulate.title')}</Title>
            <p>{t('appPage.accumulate.description1')}</p>
            <p>{t('appPage.accumulate.description2')}</p>
          </div>
        </Aux>
      );
    } else if (balance) {
      content = (
        <Aux>
          <div className="Left">
            <Title icon="cake-white">{t('appPage.balance.title')}</Title>
            <p>{t('appPage.balance.description')}</p>
          </div>
          <div className="Right">
            <img src={balanceImg} alt={''} />
          </div>
        </Aux>
      );
    } else if (use) {
      content = (
        <Aux>
          <div className="Left">
            <img src={useImg} alt={''} />
          </div>
          <div className="Right">
            <Title icon="orange-white">{t('appPage.use.title')}</Title>
            <p>{t('appPage.use.description')}</p>
            <img src={useItemsImg} alt={''} />
          </div>
        </Aux>
      );
    }
    return (
      <section className={sectionName}>
        <div className="Wrapper">
          {content}
        </div>
      </section>
    );
  }
}

export default AppContent;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18n from "i18next";

import Aux from '../Aux/Aux';
import './Layout.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import SideDrawer from '../../components/Header/Navigation/SideDrawer/SideDrawer';
import * as actions from '../../store/actions/index';

class Layout extends Component {
  state = {
    showSideDrawer: false,
    languages: [
      {
        id: 'uk',
        value: 'uk',
        text: 'Ua',
        isSelected: true
      },
      {
        id: 'ru',
        value: 'ru',
        text: 'Ru',
        isSelected: false
      },
      {
        id: 'en',
        value: 'en',
        text: 'En',
        isSelected: false
      },
      {
        id: 'ro',
        value: 'ro',
        text: 'Ro',
        isSelected: false,
      },
      {
        id: 'pl',
        value: 'pl',
        text: 'Pl',
        isSelected: false,
      }
    ],
  }

  componentDidMount() {
    this.props.onFetchGuestUser();
    this.props.onFetchAdditionalUser(); // we should remove it
  }

  sideDrawerClosedHandler = () => {
    document.querySelector('body').style.overflow = '';
    this.setState({
      showSideDrawer: false
    });
  }

  sideDrawerToggleHandler = () => {
    document.querySelector('body').style.overflow = 'hidden';
    this.setState((prevState) => {
      return {
        showSideDrawer: !prevState.showSideDrawer
      };
    });
  }

  changeLang = (lang) => {
    const { value } = lang;

    const languages = this.state.languages.map(language => language.id === lang.id ? { ...language, isSelected: true } : { ...language, isSelected: false });

    this.props.onChangeLang(value);
    i18n.changeLanguage(value);
    this.setState({ languages });
  };

  render() {
    const { showSideDrawer, languages } = this.state;
    const { currentLang, t } = this.props;

    return (
      <Aux>
        <Header
          drawerToggleClicked={this.sideDrawerToggleHandler}
          languages={languages}
          currentLang={currentLang}
          onChangeLang={this.changeLang}
          t={t}
        />
        {
          showSideDrawer ?
            <SideDrawer
              open={showSideDrawer}
              closed={this.sideDrawerClosedHandler}
              t={t}
            /> : null
        }

        <main className="Content">
          {this.props.children}
        </main>
        <Footer t={t} />
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentLang: state.localizationReducer.currentLang,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchGuestUser: () => dispatch(actions.fetchGuestUser()),
    onFetchAdditionalUser: () => dispatch(actions.fetchAdditionalUser()),  // we should remove it
    onChangeLang: (lang) => dispatch(actions.changeLang(lang))
  };
};

const LayoutTranslation = withTranslation()(Layout);

export default connect(mapStateToProps, mapDispatchToProps)(LayoutTranslation);
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import authReducer from './store/reducers/auth';
import restaurantsReducer from './store/reducers/restaurants';
import eventsReducer from './store/reducers/events';
import careerReducer from './store/reducers/career';
import loaderReducer from './store/reducers/loader';
import localizationReducer from './store/reducers/localization';

const rootReducer = combineReducers({
  authReducer,
  restaurantsReducer,
  eventsReducer,
  careerReducer,
  loaderReducer,
  localizationReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

const app = (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </I18nextProvider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import localizationJson from './assets/json/localization.json';

i18n
  .use(initReactI18next)
  .init({
    lng: 'uk',
    resources: localizationJson
  });

export default i18n;
import React from 'react';
import { Element } from 'react-scroll';

import './EventModal.scss';
import Modal from '../Modal';
import Title from '../../Title/Title';
import Description from '../../Description/Description';
import close from '../../../../assets/images/close.svg';
import closeMobile from '../../../../assets/images/close-mobile.svg';

const eventModal = (props) => (
  <Modal show={props.show} modalClosed={props.closeModal}>
    <Element className="Scroll">
      <div className="ModalContent">
        <div className="Left">
          <img src={props.selectedEvent.images_list.original} alt="poster" />
        </div>
        <div className="Right">
          <Title>{props.selectedEvent.description.title}</Title>
          <Description>{props.selectedEvent.description.description}</Description>
        </div>
      </div>
      <div className="Close">
        <img src={props.width > 767 ? close : closeMobile} alt="close" onClick={props.closeModal} />
      </div>
    </Element>


  </Modal>
);

export default eventModal;
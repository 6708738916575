import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';

import './AppPage.scss';
import Aux from '../../hoc/Aux/Aux';
import AppContent from '../../components/AppContent/AppContent';
import DownloadSection from '../../components/DownloadSection/DownloadSection';
import { ggroupTitle } from '../constants';
import AppContentMobile from '../../components/AppContentMobile/AppContentMobile';

class AppPage extends Component {
  state = {
    width: 0,
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  renderDesktopVersion = () => {
    const { t } = this.props;

    return (
      <>
        <AppContent
          sectionName="FavouriteGuestSection"
          guest={true}
          t={t}
        />
        <AppContent
          sectionName="AccumulatePointsSection"
          accumulate={true}
          t={t}
        />
        <AppContent
          sectionName="BalanceSection Yellow"
          balance={true}
          t={t}
        />
        <AppContent
          sectionName="UsePointsSection Yellow"
          use={true}
          t={t}
        />
        <DownloadSection t={t} />
      </>
    );
  };

  render() {
    const { t } = this.props;
    const { width } = this.state;
  
    return (
      <Aux>
        <Helmet>
          <title>{ggroupTitle} - {t('appPage.tabTitle')}</title>
        </Helmet>
        {width > 767 ? this.renderDesktopVersion() : <AppContentMobile t={t} />}
      </Aux>
    );
  };
};

const appPageTranslation = withTranslation()(AppPage);

export default appPageTranslation;

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  currentLang: 'uk'
};

const changeLang = (state, action) => {
  return updateObject(state, {
    currentLang: action.lang
  });
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.CHANGE_LANG: return changeLang(state, action);
    default: return state;
  };
};

export default reducer
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  loader: false
};

const setLoader = (state, action) => {
  return updateObject(state, {
    loader: action.loader,
  })
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.SET_LOADER: return setLoader(state, action);
    default: return state;
  }
};

export default reducer;
import React, { Component } from 'react';

import './Content.scss';
import Title from '../UI/Title/Title';
import Form from '../UI/Form/Form';
import Aux from '../../hoc/Aux/Aux';

class Content extends Component {
  render() {
    const mailTo = 'franchise@g-group.rest';
    const {
      sectionName,
      franchise,
      career,
      t,
      currentLang,
      restaurants,
    } = this.props;
    let content;

    const advantages = [
      t('franchisePage.advantages.advantage1'),
      t('franchisePage.advantages.advantage2'),
      t('franchisePage.advantages.advantage3'),
      t('franchisePage.advantages.advantage4'),
      t('franchisePage.advantages.advantage5'),
      t('franchisePage.advantages.advantage6'),
      t('franchisePage.advantages.advantage7'),
      t('franchisePage.advantages.advantage8'),
      t('franchisePage.advantages.advantage9'),
    ];

    const experiense = [
      t('franchisePage.experiense.experiense1'),
      t('franchisePage.experiense.experiense2'),
      t('franchisePage.experiense.experiense3'),
    ];

    const franchises = [
      t('franchisePage.franchises.franchise1'),
    ];

    if (franchise) {
      content = (
        <Aux>
          {!!restaurants.length && (
            <>
              <Title icon="glass">{t('franchisePage.title')}</Title>
              <div className="InnerWrapper">
                <div className="About">{t('franchisePage.description1', { quantity: restaurants.length })}</div>
                <div className="About">{t('franchisePage.description2')}</div>
                <div>{t('franchisePage.advantages.title')}</div>
                <ul className="Advantages">
                  {
                    advantages.map(advantage => {
                      return (
                        <li key={advantage}>{advantage}</li>
                      );
                    })
                  }
                </ul>
                <div>{t('franchisePage.experiense.title')}</div>
                <ul className="Advantages">
                  {
                    experiense.map(advantage => {
                      return (
                        <li key={advantage}>{advantage}</li>
                      );
                    })
                  }
                </ul>
                <ul className="Franchise">
                  {
                    franchises.map(franchise => {
                      return (
                        <li key={franchise}>{franchise}</li>
                      );
                    })
                  }
                </ul>
                <div className="Mail">{t('franchisePage.mailTo')} <a href={`mailto:${mailTo}`}>{mailTo}</a></div>
              </div>
            </>
          )}
        </Aux>
      );
    } else if (career) {
      content = (
        <Aux>
          <Title icon="glass">{t('careerPage.title')}</Title>
          <div className="InnerWrapper">
            <div className="About">{t('careerPage.description')}</div>
          </div>
          <Form t={t} currentLang={currentLang} />
        </Aux>
      );
    }

    return (
      <section className={sectionName}>
        <div className="Container">
          <div className="Wrapper">
            {content}
          </div>
        </div>
      </section>
    );
  }
}

export default Content;
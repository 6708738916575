import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';

import './RestaurantPage.scss';
import * as actions from '../../store/actions/index';
import RestaurantSection from '../../components/RestaurantSection/RestaurantSection';
import MenuSection from '../../components/MenuSection/MenuSection';
import PhotoGallerySection from '../../components/PhotoGallerySection/PhotoGallerySection';
import MapSection from '../../components/MapSection/MapSection';
import Loader from '../../components/Loader/Loader';
import { ggroupTitle } from '../constants';

class RestaurantPage extends Component {
  state = {
    width: 0
  };

  componentDidMount() {
    const { guestToken, currentLang, match } = this.props;

    this.props.onFetchRestaurantById(+match.params.id, guestToken, currentLang);

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps) {
    const { restaurantErr, guestToken, currentLang, match } = this.props;

    if (prevProps.guestToken !== guestToken || prevProps.currentLang !== currentLang) {
      this.props.onClearRestaurantData();
      this.props.onFetchRestaurantById(+match.params.id, guestToken, currentLang);
    }

    if (prevProps.restaurantErr !== restaurantErr) {
      this.props.history.push('/');
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    this.props.onSetLoader(false);
    this.props.onClearRestaurantData();
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const { restaurant, currentLang, t } = this.props;

    return (
      restaurant ?
        <div className="RestaurantPage">
          <Helmet>
            <title>{ggroupTitle} - {restaurant.description.title}</title>
          </Helmet>
          <RestaurantSection restaurant={restaurant} t={t} />
          <MenuSection 
            restaurant={restaurant} 
            currentLang={currentLang} 
            t={t}
          />
          <PhotoGallerySection
            style={{
              paddingBottom: '110px'
            }}
            restaurantPage={true}
            slides={restaurant.gallery}
            t={t}
            currentLang={currentLang}
          />
          <MapSection restaurant={restaurant} t={t} />
        </div> : <Loader />
    );
  };
};

const mapStateToProps = state => {
  return {
    guestToken: state.authReducer.guestToken,
    restaurant: state.restaurantsReducer.restaurant,
    restaurantErr: state.restaurantsReducer.restaurantErr,
    loader: state.loaderReducer.loader,
    currentLang: state.localizationReducer.currentLang
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchRestaurantById: (id, token, locale) => dispatch(actions.fetchRestaurantById(id, token, locale)),
    onSetLoader: (loader) => dispatch(actions.setLoader(loader)),
    onClearRestaurantData: () => dispatch(actions.clearRestaurantData()),
  };
}

const RestaurantPageTranslation = withTranslation()(RestaurantPage);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RestaurantPageTranslation));

import React from 'react';

import './Events.scss';
import Event from './Event/Event';

const events = (props) => (
  <ul className="Events">
    {
      props.events.map(event => {
        return <Event key={event.id} event={event} clicked={props.clicked} />
      })
    }
  </ul>
);

export default events;
import React from 'react';

import './Info.scss';
import Aux from '../../../hoc/Aux/Aux';

const info = (props) => {
  const {
    workTime,
    telephones,
    description,
    latitude,
    longitude,
    google_cid,
    t
  } = props;

  return (
    <Aux>
      <div className="Info WorkTime">
        <p className="Label">{t('restaurantPage.restaurantSection.workTime.label')}</p>
        <ul>
          {workTime.map(time => <li key={time} className="Name">{time}</li>)}
        </ul>
      </div>
      <div className="Info Phone">
        <p className="Label">{t('restaurantPage.restaurantSection.phone.label')}</p>
        {
          telephones.map(tel => {
            return <a key={tel} className="Name" href={`tel:${tel}`}>{tel}</a>;
          })
        }
      </div>
      <div className="Info Address">
        <p className="Label">{t('restaurantPage.restaurantSection.address.label')}</p>
        <a
          target="_blank"
          href={`https://www.google.com/maps/@${latitude},${longitude},17z/${google_cid}`}
          rel="noopener noreferrer"
          className="Name"
        >
          {description.address}
        </a>
      </div>
    </Aux>
  )
};

export default info;
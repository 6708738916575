import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  events: [],
  selectedEvent: null,
  pageSize: 6,
  totalEvents: null,
  isLoadingEvents: false
};

const fetchEvents = (state, action) => {
  return updateObject(state, {pageSize: action.pageSize});
};

const fetchEventsStart = (state, action) => {
  return updateObject(state, { isLoadingEvents: true });
}

const fetchEventsSuccess = (state, action) => {
  return updateObject(state, {
    events: action.events, 
    pageSize: action.events.length,
    totalEvents: action.totalEvents,
    isLoadingEvents: false
  });
}

const showMore = (state, action) => {
  return fetchEvents(state, action);
}

const selectEvent = (state, action) => {
  const selectedEvent = state.events.find(event => event.id === action.id);
  return updateObject(state, {selectedEvent});
}

const fetchSelectEventSuccess = (state, action) => {
  const selectedEvent = action.events.find(event => event.id === action.id);
  return updateObject(state, {selectedEvent});
}

const reducer = (state = initialState, action) => {
	switch(action.type) {
    case actionTypes.FETCH_EVENTS: return fetchEvents(state, action);
    case actionTypes.FETCH_EVENTS_START: return fetchEventsStart(state, action);
    case actionTypes.FETCH_EVENTS_SUCCESS: return fetchEventsSuccess(state, action);
    case actionTypes.SHOW_MORE: return showMore(state, action);
    case actionTypes.SELECT_EVENT: return selectEvent(state, action);
    case actionTypes.FETCH_SELECT_EVENT_SUCCESS: return fetchSelectEventSuccess(state, action);
    default: return state;
  }
}

export default reducer;
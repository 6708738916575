import React, { Component } from 'react';

import './RestaurantSection.scss';
import line from '../../assets/images/restaurant-page/line.svg';
import Title from '../UI/Title/Title';
import Description from '../UI/Description/Description';
import SocialBtns from '../SocialBtns/SocialBtns';
import Info from './Info/Info';
import { updateWorkTime } from '../../shared/workTime';
import placeholderImg from '../../assets/images/restaurant-page/placeholder.jpg';

class RestaurantSection extends Component {
  render() {
    const {
      description,
      telephones,
      main_image, site,
      instagram,
      work_time,
      latitude,
      longitude,
      google_cid,
    } = this.props.restaurant;

    const { t } = this.props;

    const updatedWorkTime = updateWorkTime(work_time, t);

    const socialBtns = [
      { name: 'Facebook', navTo: site },
      { name: 'Instagram', navTo: instagram }
    ];

    const mockMainImage = placeholderImg;

    return (
      <section className="RestaurantSection">
        <div className="Container">
          <div className="Left"><img src={main_image ? main_image : mockMainImage} alt="restaurant" /></div>
          <div className="Right">
            <Title icon="glass">{t('restaurantPage.restaurantSection.title')} {description.title}</Title>
            <Description className="Description">{description.subtitle}</Description>
            <SocialBtns socialBtns={socialBtns} />
            <img className="Line" src={line} alt="line" />
            <Info
              workTime={updatedWorkTime}
              telephones={telephones}
              description={description}
              latitude={latitude}
              longitude={longitude}
              google_cid={google_cid}
              t={t}
            />
          </div>
        </div>
      </section>
    );
  }
};

export default RestaurantSection;
import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Form.scss';
import Input from '../Input/Input';
import { updateObject, checkValidity } from '../../../shared/utility';
import Button from '../Button/Button';
import * as actions from '../../../store/actions/index';

let fileRef;

class Form extends Component {
  state = {
    form: {
      name: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'Введите имя',
          label: 'Имя'
        },
        value: '',
        validation: {
          required: true,
          minLength: 2,
          maxLength: 10,
          onlyCharacters: true
        },
        valid: false,
        touched: false
      },
      phone: {
        elementType: 'phone',
        elementConfig: {
          type: 'tel',
          placeholder: '+38 (000) 000 00 00',
          label: 'Номер телефона'
        },
        value: '',
        validation: {
          required: true,
          minLength: 19,
          maxLength: 19
        },
        valid: false,
        touched: false
      },
      file: {
        elementType: 'file',
        elementConfig: {
          type: 'file',
          explanation: 'Файл должен быть формата .pdf и не превышать 3 Мб.'
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
    },
    formIsValid: false
  }

  componentDidMount() {
    this.changeLanguage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentLang !== this.props.currentLang) {
      this.changeLanguage();
    }
  }

  changeLanguage = () => {
    const { currentLang, t } = this.props;

    if (currentLang === 'ru') {
      document.querySelector('.filepond--drop-label').className = 'filepond--drop-label';
    } else if (currentLang === 'uk') {
      document.querySelector('.filepond--drop-label').className = 'filepond--drop-label uk';
    } else if (currentLang === 'en') {
      document.querySelector('.filepond--drop-label').className = 'filepond--drop-label en';
    } else if (currentLang === 'ro') {
      document.querySelector('.filepond--drop-label').className = 'filepond--drop-label ro';
    } else if (currentLang === 'pl') {
      document.querySelector('.filepond--drop-label').className = 'filepond--drop-label pl';
    }

    const formClone = { ...this.state.form };

    formClone.name.elementConfig.placeholder = t('careerPage.form.name.placeholder');
    formClone.name.elementConfig.label = t('careerPage.form.name.label');

    formClone.phone.elementConfig.label = t('careerPage.form.phone.label');

    formClone.file.elementConfig.explanation = t('careerPage.form.file.explanation');

    this.setState({ form: formClone });
  }

  inputContent = (formElement) => {
    return <Input
      key={formElement.id}
      label={formElement.config.label}
      elementType={formElement.config.elementType}
      elementConfig={formElement.config.elementConfig}
      value={formElement.config.value}
      invalid={!formElement.config.valid}
      shouldValidate={formElement.config.validation}
      touched={formElement.config.touched}
      changed={(event) => this.inputChangedHandler(event, formElement.id)}
      removed={() => this.removeFile()}
      t={this.props.t}
    />;
  }

  removeFile = () => {
    const updatedForm = {
      ...this.state.form,
      file: {
        ...this.state.form.file,
        value: '',
        valid: false
      }
    };

    this.setState({ form: updatedForm, formIsValid: false });
  };

  inputChangedHandler = (event, inputIdentifier) => {
    let value;
    if (inputIdentifier === 'file') {
      value = event.getFile().getFileEncodeBase64String();
      fileRef = event;
    } else {
      value = event.target.value;
    }

    const updatedFormElement = updateObject(this.state.form[inputIdentifier], {
      value,
      valid: checkValidity(value.trim(), this.state.form[inputIdentifier].validation),
      touched: true
    });

    const udpatedForm = { ...this.state.form };
    udpatedForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;

    for (let inputIdentifier in udpatedForm) {
      formIsValid = udpatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ form: udpatedForm, formIsValid });
  }

  resetForm = () => {
    const obj = { ...this.state.form };

    for (let formElementIdentifier in obj) {
      obj[formElementIdentifier].value = '';
      obj[formElementIdentifier].touched = false;
      obj[formElementIdentifier].valid = false;
    }

    fileRef.removeFile();

    this.setState({ form: obj, formIsValid: false });
  }

  formHandler = (e) => {
    e.preventDefault();
    const career = {};

    for (let formElementIdentifier in this.state.form) {
      if (formElementIdentifier === 'phone') {
        const value = this.state.form[formElementIdentifier].value;
        const valueWithoutBrackets = value.slice(0, 3) + value.slice(5, 8) + value.slice(10, 13) + value.slice(14, 16) + value.slice(17, 19);
        career[formElementIdentifier] = valueWithoutBrackets;
      } else {
        career[formElementIdentifier] = this.state.form[formElementIdentifier].value;
      }
    }

    this.resetForm();
    this.props.onCareer(career);
  }

  render() {
    const { currentLang } = this.props;
    const formElementsArray = [];

    for (let key in this.state.form) {
      formElementsArray.push({
        id: key,
        config: this.state.form[key]
      });
    }

    return (
      <form>
        <div className="Wrapper">
          {
            formElementsArray.map(formElement => {
              if (formElement.id === 'name' || formElement.id === 'phone') {
                return this.inputContent(formElement);
              }
            })
          }
        </div>

        {
          formElementsArray.map(formElement => {
            if (formElement.id === 'file') {
              return this.inputContent(formElement);
            }
          })
        }

        <Button className={`Submit ${currentLang === 'en' ? 'en' : ''}`} clicked={this.formHandler} disabled={!this.state.formIsValid}>{this.props.t('careerPage.form.sendBtn')}</Button>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentLang: state.localizationReducer.currentLang
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCareer: (data) => dispatch(actions.career(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
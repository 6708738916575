import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import * as actions from '../../store/actions/index';
import EventModal from '../../components/UI/Modal/EventModal/EventModal';
import { ggroupTitle } from '../constants';

class EventPage extends Component {
  state = {
    isShowModal: false,
    width: 0
  };

  componentDidMount() {
    const { match, guestToken, currentLang, additionalToken } = this.props;

    this.props.onSelectEvent(+match.params.id, additionalToken, currentLang);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps, prevState) {
    const { match, guestToken, currentLang, additionalToken } = this.props;

    if (prevProps.additionalToken !== additionalToken) {
      this.props.onSelectEvent(+match.params.id, additionalToken, currentLang);
    }

    if (prevProps.selectedEvent !== this.props.selectedEvent) {
      document.querySelector('body').style.overflow = 'hidden';
      this.setState({ isShowModal: true });
    }

    window.onpopstate = (e) => {
      document.querySelector('body').style.overflow = '';
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  closeModal = () => {
    document.querySelector('body').style.overflow = '';
    this.setState({ isShowModal: false });
    this.props.history.goBack();
  }

  render() {
    const { selectedEvent } = this.props;
    const { isShowModal, width } = this.state;

    return (
      <div className="EventWrapper">
        {
          selectedEvent ?
            <Helmet>
              <title>{ggroupTitle} - {selectedEvent.description.title}</title>
            </Helmet> : null
        }
        {
          isShowModal ?
            <EventModal
              show={isShowModal}
              selectedEvent={selectedEvent}
              width={width}
              closeModal={() => this.closeModal()}
            /> : null
        }
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    guestToken: state.authReducer.guestToken,
    additionalToken: state.authReducer.additionalToken, // should remove it
    selectedEvent: state.eventsReducer.selectedEvent,
    currentLang: state.localizationReducer.currentLang
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSelectEvent: (id, token, locale) => dispatch(actions.selectEvent(id, token, locale))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPage);

import React from 'react';
import { withRouter } from 'react-router-dom';

import mainLogo from '../../assets/images/logo.svg';
import './Logo.scss';

const logo = (props) => {
  function redirectToMain() {
    props.history.push({pathname: '/'});
    window.scrollTo(0, 0);
  }

  return (
    <div className="Logo" onClick={() => redirectToMain()}>
      <img src={mainLogo} alt="logo" />
    </div>
  );
};

export default withRouter(logo);
import React from 'react';

import './NavigationItems.scss';
import NavigationItem from './NavigationItem/NavigationItem';

const navigationItems = (props) => {
  const { t, closed } = props;

  const links = [
    { route: '/', exact: true, name: t('header.nav.main') },
    { route: '/restaurants', name: t('header.nav.restaurants') },
    { route: '/events', name: t('header.nav.events') },
    { route: '/franchise', name: t('header.nav.franchise') },
    { route: '/career', name: t('header.nav.career') },
    { route: '/bonusnaya-sistema-lyubimyj-gost', name: t('header.nav.app') },
    { href: 'https://order.eatery.club/chinchin/address', name: t('header.nav.chinchin'), target: '_blank' },
  ];

  return (
    <ul className="NavigationItems">
      {
        links.map(link => {
          const { name, route, exact, href, target } = link;

          return (
            <NavigationItem
              key={name}
              closed={closed}
              link={route}
              exact={exact}
              href={href}
              target={target}
            >
              {name}
            </NavigationItem>
          );
        })
      }
    </ul>
  );
};

export default navigationItems;